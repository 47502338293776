import { lazy } from "react";
import { useRoutes } from "react-router-dom";

const HomePage = lazy(() => import("../pages/HomePage"));

function AppRouter () {
 const router = useRoutes([
    {
      path: "/",
      element: <HomePage />,
    },
  ]);
  return router;
};

export default AppRouter;